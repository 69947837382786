/* eslint-disable import/prefer-default-export */

export function getPositionFromString(coordinate) {
  const marker = coordinate.split(',', 2);
  let position = { lat: marker[0].trim(), lng: (marker[1] || '').trim() };
  if (Number.isNaN(parseFloat(position.lat)) || Number.isNaN(parseFloat(position.lng))) {
    position = false;
  } else {
    position.lat = parseFloat(position.lat); // .toFixed(7);
    position.lng = parseFloat(position.lng); // .toFixed(7);
  }

  return position;
}
