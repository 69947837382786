import { render, staticRenderFns } from "./WGeo.vue?vue&type=template&id=e49ab9d6&"
import script from "./WGeo.vue?vue&type=script&lang=js&"
export * from "./WGeo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.5_e6f9754d45536372aa6e5c6b320144db/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports