<template>
  <div class="flex flex-column">
    <div
      class="flex flex-row flex-grow flex-shrink-0 justify-items-stretch align-items-center pt-3"
    >
      <input
        v-model="coordinatesLoadMode"
        type="radio"
        name="coordinates"
        id="CoordinatesManual"
        value="manual"
      />
      <label for="CoordinatesManual" class="mb-0 ml-2">
        {{
          $t(
            "segmentation.geo.subtitles.place-search.options.coordinates.manual.label"
          )
        }}
      </label>
    </div>
    <div class="input pl-4" v-if="coordinatesLoadMode === 'manual'">
      <template v-if="getMarkerAddVisible || !currentMarkers.length">
        <input
          type="text"
          ref="coordinates"
          :placeholder="$t(placeholder.manual)"
          v-focus
          v-model="coordinate"
          @keyup.esc="setMarkerAddVisible(false)"
          @keyup.enter="newMarker"
        />
        <div class="icon" @click="$refs.coordinates.focus()">
          <feather-icon icon="SearchIcon" style="max-width: 1.1666667em" />
        </div>
      </template>
      <div class="step-list" v-if="currentMarkers.length">
        <vs-list>
          <vs-list-item
            v-for="(marker, ix) in currentMarkers"
            :key="markerName(marker, false)"
            :subtitle="markerName(marker)"
            title=""
          >
            <div
              class="minus"
              @click="removeMarker(ix)"
              :title="
                removeTextManual ? $t(removeTextManual, marker.position) : ''
              "
            >
              -
            </div>
          </vs-list-item>
        </vs-list>
        <div
          class="add"
          v-if="!getMarkerAddVisible"
          @click="setMarkerAddVisible(true)"
        >
          <div class="plus">+</div>
          <div>{{ addTextManual ? $t(addTextManual) : "" }}</div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-row flex-grow flex-shrink-0 justify-items-stretch align-items-center pt-3"
    >
      <input
        v-model="coordinatesLoadMode"
        type="radio"
        name="coordinates"
        id="CoordinatesFile"
        value="file"
      />
      <label for="CoordinatesFile" class="mb-0 ml-2">
        {{
          $t(
            "segmentation.geo.subtitles.place-search.options.coordinates.file.label"
          )
        }}
      </label>
    </div>
  </div>
</template>

<script>
// import SEGMENTATION from '@/constants/segmentation';
import { getPositionFromString } from '@/helpers/coordinates';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'WSegmentacionStepGeoCoordinates',
  data() {
    return {
      placeholder: {
        manual: 'segmentation.geo.subtitles.place-search.options.coordinates.manual.placeholder',
        file: 'segmentation.geo.subtitles.place-search.options.coordinates.file.placeholder',
      },
      addTextManual: 'segmentation.geo.subtitles.place-search.options.coordinates.manual.add',
      addTextFile: 'segmentation.geo.subtitles.place-search.options.coordinates.file.add',
      removeTextManual: 'segmentation.geo.subtitles.place-search.options.coordinates.manual.remove',
      removeTextFile: 'segmentation.geo.subtitles.place-search.options.coordinates.file.remove',
      coordinate: '',
    };
  },
  computed: {
    ...mapGetters({
      markers: 'audience/getMarkers',
      getMarkersFromFile: 'audience/getMarkersFromFile',
      getMarkerAddVisible: 'audience/getMarkerAddVisible',
      coordinatesLoadModeSelected: 'audience/getCoordinatesLoadModeSelected',
    }),
    coordinatesLoadMode: {
      get() {
        return this.coordinatesLoadModeSelected;
      },
      set(option) {
        this.setCoordinatesLoadModeSelected(option);
      },
    },
    currentMarkers() {
      return this.coordinatesLoadMode === 'manual' ? this.markers : this.getMarkersFromFile;
    },
  },
  methods: {
    ...mapActions({
      addMarker: 'audience/addMarker',
      loadMarkersFromFile: 'audience/loadMarkersFromFile',
      removeMarker: 'audience/removeMarker',
      setMarkerAddVisible: 'audience/setMarkerAddVisible',
      setCoordinatesLoadModeSelected: 'audience/setCoordinatesLoadModeSelected',
    }),
    markerName(marker) {
      return `${marker.position.lat},${marker.position.lng}`;
    },
    newMarker() {
      const position = getPositionFromString(this.coordinate);
      if (position) {
        const markerFilter = function markerFilter(marker) {
          // eslint-disable-next-line eqeqeq
          return marker.position.lat == position.lat && marker.position.lng == position.lng;
        };
        const exists = !!this.markers.filter(markerFilter).length;

        if (!exists && this.addMarker(position)) {
          this.setMarkerAddVisible(false);
          this.coordinate = '';
        }

        if (exists) {
          this.coordinate = '';
          console.info(position, 'exists');
        }
      }
    },
  },
};
</script>
