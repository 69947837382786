<template>
  <div class="input">
    <template v-if="showSearch || !places.length">
      <gmap-autocomplete
        :options="options"
        ref="search"
        type="text"
        :placeholder="$t(placeholder)"
        v-on:keyup.esc="$emit('hide-search')"
        v-focus
        @place_changed="selected"
      />
      <div class="icon" @click="$refs.search.focus()">
        <feather-icon icon="SearchIcon" style="max-width: 1.1666667em" />
      </div>
    </template>
    <div class="step-list" v-if="places.length">
      <vs-list>
        <vs-list-item
          v-for="(place, ix) in places"
          :key="place.formatted_address"
          :subtitle="place.formatted_address"
          title=""
        >
          <div
            class="minus"
            @click="$emit('remove', ix)"
            :title="
              removeText
                ? $t(removeText, { place: place.formatted_address })
                : ''
            "
          >
            -
          </div>
        </vs-list-item>
      </vs-list>
      <div class="add" v-if="!showSearch" @click="$emit('show-search')">
        <div class="plus">+</div>
        <div>{{ addText ? $t(addText) : "" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WSegmentacionStepGeoAddress',
  props: {
    places: {
      type: Array,
      default() {
        return [];
      },
    },
    showSearch: {
      type: Boolean,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {
          types: ['geocode'],
        };
      },
    },
    removeText: {
      type: String,
      default: 'segmentation.geo.subtitles.location-search.remove',
    },
    addText: {
      type: String,
      default: 'segmentation.geo.subtitles.location-search.add',
    },
  },
  methods: {
    selected(place) {
      this.$emit('add', place);
      this.$emit('hide-search');
    },
  },
};
</script>

<style>
</style>
