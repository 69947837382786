<template>
  <div class="w-segmentation">
    <w-stepper v-model="step" :steps="4" />
    <w-step1 v-if="step === 1" class="step-wrapper" />
    <w-step2 v-if="step === 2" class="step-wrapper" />
    <w-step3 v-if="step === 3" class="step-wrapper" />
    <w-step4 v-if="step === 4" class="step-wrapper" />
  </div>
</template>

<script>
import WStepper from '@/components/custom/generic/WStepper.vue';
import WStep1 from '@/components/custom/segmentation/WGeo.vue';
import WStep2 from '@/components/custom/segmentation/WApps.vue';
import WStep3 from '@/components/custom/segmentation/WSmartphone.vue';
import WStep4 from '@/components/custom/segmentation/WDemographics.vue';
// import { mapActions } from 'vuex';

export default {
  name: 'WSegmentation',
  components: {
    WStepper,
    WStep1,
    WStep2,
    WStep3,
    WStep4,
  },
  data() {
    return {
      step: 1,
    };
  },
};
</script>
