<template>
  <gmap-map
    :center="center"
    :options="options"
    style="
      width: 100%;
      min-height: 320px;
      height: 100%;
      border-radius: 5px;
      overflow: hidden;
      flex-grow: 1;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    "
  >
    <gmap-marker
      :key="index"
      v-for="(m, index) in visibleMarkers"
      :position="m.position"
      :clickable="m.clickable || true"
      :draggable="m.draggable || true"
      @click="$emit('update:center', m.position)"
    />
  </gmap-map>
</template>

<script>
import styles from '@/assets/google-map-styles';
import { gmapApi } from 'gmap-vue';

export default {
  name: 'WMap',
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Object,
      default: () => {},
    },
    zoom: {
      type: Number,
      default: 12,
    },
    bounds: {
      type: [null, Object],
      default: null,
    },
  },
  data() {
    return {
      styles,
    };
  },
  computed: {
    google: gmapApi,
    visibleMarkers() {
      return this.markers.filter((m) => (typeof m.visible !== typeof undefined ? m.visible : true));
    },
    options() {
      return {
        styles,
        center: this.center,
        zoom: this.zoom,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        restriction: {
          latLngBounds: this.bounds,
          strictBounds: false,
        },
      };
    },
  },
};
</script>
