<template>
  <div class="flex flex-shrink-0 flex-grow flex-row flex-no-wrap">
    <w-segmentation />
    <w-map
      :center="center"
      :zoom="zoom"
      :markers="markers"
      class="flex-grow flex-shrink"
    />
  </div>
</template>

<script>
import WMap from '@/components/custom/WMap.vue';
import WSegmentation from '@/components/custom/WSegmentation.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CampaignsCreation',
  components: {
    WMap,
    WSegmentation,
  },
  // data() {
  //   return {
  //   };
  // },
  computed: {
    ...mapGetters({
      center: 'audience/getCenter',
      zoom: 'audience/getZoom',
      markers: 'audience/getMarkers',
    }),
  },
};
</script>
