<template>
  <div class="geo">
    <div class="step-title-icon">
      <img src="@/assets/images/icons/map-marker.svg" />
    </div>
    <div class="step-title">{{ $t("segmentation.geo.title") }}</div>
    <div class="step-description">{{ $t("segmentation.geo.description") }}</div>
    <!-- Geographic site -->
    <div class="step-subtitle">
      {{ $t("segmentation.geo.subtitles.location-search.title") }}
    </div>
    <div class="step-description">
      {{ $t("segmentation.geo.subtitles.location-search.description") }}
    </div>
    <div class="step-content">
      <w-address
        :show-search="locationVisible"
        :options="locationOptions"
        :places="locations"
        placeholder="segmentation.geo.subtitles.location-search.placeholder"
        removeText="segmentation.geo.search.remove"
        addText="segmentation.geo.search.add"
        @add="locationSelected"
        @remove="removeLocation"
        @show-search="locationShow"
        @hide-search="locationHide"
      />
    </div>
    <!-- Specific site -->
    <div class="step-subtitle">
      {{ $t("segmentation.geo.subtitles.place-search.title") }}
    </div>
    <div class="step-description">
      {{ $t("segmentation.geo.subtitles.place-search.description") }}
    </div>
    <div class="step-content">
      <div class="input">
        <select v-model="placeOptionSelected">
          <option
            v-for="elem in placesLoadingOptions"
            :key="elem.value"
            :value="elem.value"
            :selected="placeOptionSelected === elem.value"
          >
            {{ elem.name }}
          </option>
        </select>
      </div>
      <div class="step-content" v-if="placeOptionSelected === 'address'">
        <div class="step-description">
          {{
            $t(
              "segmentation.geo.subtitles.place-search.options.address.description"
            )
          }}
        </div>
        <w-address
          :show-search="addressVisible"
          :options="addressOptions"
          :places="addresses"
          placeholder="segmentation.geo.subtitles.place-search.options.address.placeholder"
          removeText="segmentation.geo.search.remove"
          addText="segmentation.geo.search.add"
          @add="addressSelected"
          @remove="removeAddress"
          @show-search="addressShow"
          @hide-search="addressHide"
        />
      </div>
      <div v-else class="step-content">
        <w-coordinates v-if="placeOptionSelected === 'coordinates'" />
        <w-places v-else-if="placeOptionSelected === 'places'" />
      </div>
    </div>
    <!-- RADIUS -->
    <div class="step-subtitle">
      {{ $t("segmentation.geo.subtitles.radius.title") }}
    </div>
    <div class="step-description">
      {{ $t("segmentation.geo.subtitles.radius.description") }}
    </div>
    <div class="step-content">
      <div class="input number">
        <input
          v-model="radius"
          type="number"
          min="50"
          step="50"
          class="border-0 m-0 p-0"
        />
        <div class="unit">
          {{ $t("segmentation.geo.subtitles.radius.meters") }}
        </div>
      </div>
    </div>

    <div class="step-subtitle">
      {{ $t("segmentation.geo.subtitles.user-location.title") }}
    </div>
    <div class="step-description">
      {{ $t("segmentation.geo.subtitles.user-location.description") }}
    </div>
    <div
      class="step-content flex flex-row flex-grow flex-shrink-0 justify-items-stretch"
    >
      <div class="flex flex-row flex-grow flex-shrink-0 align-items-center">
        <input
          v-model="residents"
          type="checkbox"
          name="Residents"
          id="Residents"
          :value="SEGMENTATION.GEO.RESIDENTS"
        />
        <label for="Residents" class="mb-0 ml-2">
          {{ $t("segmentation.geo.subtitles.user-location.residents") }}
        </label>
      </div>
      <div class="flex flex-row flex-grow flex-shrink-0 align-items-center">
        <input
          v-model="residents"
          type="checkbox"
          name="residents[]"
          id="NotResidents"
          :value="SEGMENTATION.GEO.NOT_RESIDENTS"
        />
        <label for="NotResidents" class="mb-0 ml-2">
          {{ $t("segmentation.geo.subtitles.user-location.not-residents") }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import WAddress from '@/components/custom/segmentation/Geo/WSegmentacionGeoAddress.vue';
import WCoordinates from '@/components/custom/segmentation/Geo/WSegmentacionStepGeoCoordinates.vue';
import WPlaces from '@/components/custom/segmentation/Geo/WSegmentacionStepGeoPlaces.vue';
import { mapActions, mapGetters } from 'vuex';
import SEGMENTATION from '@/constants/segmentation';

export default {
  name: 'Geo',
  components: {
    WAddress,
    WCoordinates,
    WPlaces,
  },
  props: {},
  data() {
    return {
      SEGMENTATION,
      location: '',
      place: '',
      placeVisible: true,
      locationOptions: {
        types: ['(regions)'],
      },
      addressOptions: {
        types: ['address'],
        bounds: this.locations ? this.locations[this.locationBoundSelected].geometry.viewport : null,
        strictbounds: true,
      },
      establishmentOptions: {
        types: ['establishment'],
        strictbounds: true,
      },
      placesLoadingOptions: [
        { value: '-', name: this.$t('segmentation.geo.subtitles.user-location.option.-') },
        // { value: 'address', name: this.$t('segmentation.geo.subtitles.user-location.option.address') },
        // { value: 'places', name: this.$t('segmentation.geo.subtitles.user-location.option.places') },
        { value: 'coordinates', name: this.$t('segmentation.geo.subtitles.user-location.option.coordinates') },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getLocationBoundSelected: 'audience/getLocationBoundSelected',
      locations: 'audience/getLocations',
      addresses: 'audience/getAddresses',
      getRadius: 'audience/getRadius',
      getResidents: 'audience/getResidents',
      getLocationVisible: 'audience/getLocationVisible',
      getAddressVisible: 'audience/getAddressVisible',
      getPlaceOptionSelected: 'audience/getPlaceOptionSelected',
    }),
    radius: {
      get() {
        return this.getRadius;
      },
      set(radius) {
        this.setRadius(radius);
      },
    },
    locationBoundSelected: {
      get() {
        return this.getLocationBoundSelected;
      },
      set(index) {
        this.setLocationBoundSelected(index);
      },
    },
    residents: {
      get() {
        const value = [];
        // eslint-disable-next-line no-bitwise
        const residents = !!(this.getResidents & SEGMENTATION.GEO.RESIDENTS); // bitmap value
        // eslint-disable-next-line no-bitwise
        const notResidents = !!(this.getResidents & SEGMENTATION.GEO.NOT_RESIDENTS); // bitmap value

        if (residents) {
          value.push(SEGMENTATION.GEO.RESIDENTS);
        }
        if (notResidents) {
          value.push(SEGMENTATION.GEO.NOT_RESIDENTS);
        }
        return value;
      },
      set(residents) {
        const all = SEGMENTATION.GEO.RESIDENTS + SEGMENTATION.GEO.NOT_RESIDENTS;
        const some = residents.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        this.setResidents(some || all);
      },
    },
    locationVisible: {
      get() {
        return this.getLocationVisible;
      },
      set(show) {
        this.setLocationVisible(show);
      },
    },
    addressVisible: {
      get() {
        return this.getAddressVisible;
      },
      set(show) {
        this.setAddressVisible(show);
      },
    },
    placeOptionSelected: {
      get() {
        return this.getPlaceOptionSelected;
      },
      set(option) {
        this.setPlaceOptionSelected(option);
      },
    },
  },
  created() {
    this.placeOptionSelected = 'coordinates';
  },
  methods: {
    ...mapActions({
      setResidents: 'audience/setResidents',
      setRadius: 'audience/setRadius',
      setLocationVisible: 'audience/setLocationVisible',
      setAddressVisible: 'audience/setAddressVisible',
      setPlaceOptionSelected: 'audience/setPlaceOptionSelected',
      addLocation: 'audience/addLocation',
      removeLocation: 'audience/removeLocation',
      addAddress: 'audience/addAddress',
      removeAddress: 'audience/removeAddress',
      setLocationBoundSelected: 'audience/setLocationBoundSelected',
    }),
    locationShow() {
      this.locationVisible = true;
    },
    locationHide() {
      this.locationVisible = false;
    },
    addressShow() {
      this.addressVisible = true;
    },
    addressHide() {
      this.addressVisible = false;
    },
    locationSelected(location) {
      this.addLocation(location);
      this.locationHide();
    },
    addressSelected(address) {
      this.addAddress(address);
      this.addressHide();
    },
  },
};
</script>
