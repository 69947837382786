<template>
  <div class="w-stepper">
    <div
      v-for="iStep in steps"
      :key="iStep"
      class="flex flex-row"
      :class="
        isLastStep(iStep)
          ? 'flex-grow-0 flex-shrink'
          : 'flex-grow flex-shrink-0'
      "
      :data-step="iStep"
    >
      <div
        class="circle"
        :class="{
          past: isPast(iStep),
          future: isFuture(iStep),
        }"
        @click="$emit('input', iStep)"
      ></div>
      <div
        v-if="!isLastStep(iStep)"
        class="line"
        :class="{
          past: isPast(iStep),
          future: isFuture(iStep),
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WStepper',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    steps: {
      type: Number,
      required: true,
    },
  },
  computed: {
    inputListeners() {
      const vm = this;
      // `Object.assign` merges objects together to form a new object
      return {
        // We add all the listeners from the parent
        ...this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        // This ensures that the component works with v-model
        input(event) {
          vm.$emit('input', event.target.value);
        },
      };
    },
    isSelected() {
      return this.value === this.steps;
    },
  },
  methods: {
    isLastStep(iStep) {
      return iStep === this.steps;
    },
    isPast(iStep) {
      return this.value > iStep;
    },
    isFuture(iStep) {
      return this.value < iStep;
    },
  },
};
</script>

<style lang="scss" scoped>
.w-stepper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-shrink: 0;
  border-bottom: 1px solid #e0e0e0; /*#aea9a9;*/
  padding: 25px 0 30px;
  margin-bottom: 30px;
  & .circle {
    cursor: pointer;
  }
}
.circle {
  width: 16px;
  height: 16px;
  justify-self: start;
  border-radius: 100%;
  border-width: 2px;
  border-color: #e61e62;
  &.past {
    background-color: #e61e62;
  }
  &.future {
    border: #e0e0e0;
    background-color: #e0e0e0;
  }
}
.line {
  height: 2px;
  align-self: center;
  flex-grow: 1;
  background-color: #e0e0e0;
  &.past {
    background-color: #e61e62;
  }
  &.future {
    background-color: #e0e0e0;
  }
}
</style>
